// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Extra small devices (phones) */
@media (max-width: 575px) {

    /* Your styles for extra small screens */
    .home-select-drp {
        display: flex;
        align-items: flex-start !important;
        justify-content: space-between !important;
        padding: 5px 10px !important;
    }

    .posSearchData_1 {
        width: 75%;
    }

    .posSearchData_2 {
        width: 15%;
    }

    .timegym-home .card-body {
        padding: 15px !important;
        justify-content: flex-start !important;
    }

    .posMobileView {
        flex-wrap: wrap !important;
    }

}

/* Small devices (tablets) */
@media (min-width: 576px) and (max-width: 767px) {
    /* Your styles for small screens */
}

/* Medium devices (laptops, desktops) */
@media (min-width: 768px) and (max-width: 991px) {
    /* Your styles for medium screens */
}

/* Large devices (desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
    /* Your styles for large screens */
}

/* Extra large devices (large desktops) */
@media (min-width: 1200px) and (max-width: 1399px) {
    /* Your styles for extra large screens */
}

/* Extra extra large devices (extra large desktops) */
@media (min-width: 1400px) {
    /* Your styles for extra extra large screens */
}`, "",{"version":3,"sources":["webpack://./src/components/css/Responsive.css"],"names":[],"mappings":"AAAA,iCAAiC;AACjC;;IAEI,wCAAwC;IACxC;QACI,aAAa;QACb,kCAAkC;QAClC,yCAAyC;QACzC,4BAA4B;IAChC;;IAEA;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;;IAEA;QACI,wBAAwB;QACxB,sCAAsC;IAC1C;;IAEA;QACI,0BAA0B;IAC9B;;AAEJ;;AAEA,4BAA4B;AAC5B;IACI,kCAAkC;AACtC;;AAEA,uCAAuC;AACvC;IACI,mCAAmC;AACvC;;AAEA,6BAA6B;AAC7B;IACI,kCAAkC;AACtC;;AAEA,yCAAyC;AACzC;IACI,wCAAwC;AAC5C;;AAEA,qDAAqD;AACrD;IACI,8CAA8C;AAClD","sourcesContent":["/* Extra small devices (phones) */\n@media (max-width: 575px) {\n\n    /* Your styles for extra small screens */\n    .home-select-drp {\n        display: flex;\n        align-items: flex-start !important;\n        justify-content: space-between !important;\n        padding: 5px 10px !important;\n    }\n\n    .posSearchData_1 {\n        width: 75%;\n    }\n\n    .posSearchData_2 {\n        width: 15%;\n    }\n\n    .timegym-home .card-body {\n        padding: 15px !important;\n        justify-content: flex-start !important;\n    }\n\n    .posMobileView {\n        flex-wrap: wrap !important;\n    }\n\n}\n\n/* Small devices (tablets) */\n@media (min-width: 576px) and (max-width: 767px) {\n    /* Your styles for small screens */\n}\n\n/* Medium devices (laptops, desktops) */\n@media (min-width: 768px) and (max-width: 991px) {\n    /* Your styles for medium screens */\n}\n\n/* Large devices (desktops) */\n@media (min-width: 992px) and (max-width: 1199px) {\n    /* Your styles for large screens */\n}\n\n/* Extra large devices (large desktops) */\n@media (min-width: 1200px) and (max-width: 1399px) {\n    /* Your styles for extra large screens */\n}\n\n/* Extra extra large devices (extra large desktops) */\n@media (min-width: 1400px) {\n    /* Your styles for extra extra large screens */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
